import { FormControlLabel, Slider, Switch } from '@mui/material';
import { AlertColor } from '@mui/material/Alert';
import dayjs from 'dayjs';
import React, { useState, useEffect, ChangeEvent } from 'react';
import DatePicker from "react-datepicker";

// utility
import Api from "@/api/admin-api";
import ScraperApi from "@/api/scraper-api"
import CancelBtn from '@/components/Buttons/CancelBtn';
import SubmitButton from '@/components/Buttons/ScrapeButton';
import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import ScrapeMetrics from "@/components/Metrics";
// component
import ScraperHeader from '@/components/ScraperForm/Header';
import SaveDirectory from '@/components/ScraperForm/SaveDirectory';
import ScrapeJob from '@/components/ScraperForm/ScrapeJob';
import UsernamePassword from '@/components/ScraperForm/UsernamePassword';
import { ConfigRunMetric, SnackbarSeverity, ScraperProps } from "@/constants";

import "react-datepicker/dist/react-datepicker.css";

function BbyCpfrScraper(props: ScraperProps) {
  const {
    botCode,
    name,
    description,
    config,
    setConfigState,
    cred,
    setCredState,
    job,
    setJobState
  } = props;

  // state  
  const [isScraping, setIsScraping] = useState<boolean>(false);

  const showSnack = useSnackbar();

   // hist state
   const [isHist, setIsHist] = useState<boolean>(false);
   //const [isHistFull, setIsHistFull] = useState<boolean>(false);

  // poll isscraping
  useEffect(() => {
    // make initial api call
    Api.getIsScraping(config?.ConfigId)
      .then((res: any) => {
          setIsScraping(res.IsScraping)
      }).fail(() => {
          setIsScraping(false)
      });
    // if a config is selected poll api every 30 seconds unless not scraping
    if (config) {
      const isScrapingInterval = setInterval(() => {
        Api.getIsScraping(config?.ConfigId)
          .then((res: any) => {
              setIsScraping(res.IsScraping)
              if (!res.IsScraping) {
                  clearInterval(isScrapingInterval)
              }
          }).fail(() => {
              clearInterval(isScrapingInterval)
              setIsScraping(false)
          });
      }, 30000);
      return () => {
          clearInterval(isScrapingInterval)
      };
    }
  }, [isScraping, config?.ConfigId]);

  const handleShowSnackBar = (message: string, severity: AlertColor) => {
    showSnack(message, severity);
  };

  // functions
  function handleSubmit(e: any) {
    // Prevent the browser from reloading the page
    e.preventDefault();
    
    // Read the form data as json
    const formJson = Object.fromEntries(new FormData(e.target).entries());

    // add react state to form payload
    if (isHist) {
      formJson.isHist = JSON.stringify(isHist);
      formJson.DaysBack = daysBack.toString()
    }

    if (config?.ConfigId && config.ConfigId > 0) {
      ScraperApi.scrapeBbyCpfr(config?.ConfigId, formJson)
        .then((resp: ConfigRunMetric) => {
          setIsScraping(true)
          handleShowSnackBar(`Started bot config: "${config?.Name}".`, SnackbarSeverity.INFO);
        }).catch((err: any) => {
          setIsScraping(false)
          handleShowSnackBar(`Error starting the bot: ${err.statusText}.`, SnackbarSeverity.ERROR)

      });
    } else {
      handleShowSnackBar(`First create a config?.`, SnackbarSeverity.INFO);
    }
  }
  // const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const [histDate, setHistDate] = useState<Date|null>(new Date());
  const [daysBack, setDaysBack] = useState(0);
  
  const calculateDifference = (date: Date|null) => {
    if (histDate && date) {
      const endDate = new Date();
      const timeDifference = endDate.getTime() - date.getTime();
      const daysBack = Math.round(timeDifference / (1000 * 3600 * 24));
      setDaysBack(daysBack);
      setHistDate(date)
    }
  };

  const isHistable = (date: Date) => {
    const currentDate = new Date();
    const pastDate = new Date(currentDate)
    pastDate.setDate(pastDate.getDate() - 10)
    return date > pastDate && date < currentDate; // BBY only allows 10 days back
  }

  const handleSlideRange = (event: Event, newValue: number | number[]) => {
    const daysback = newValue as number
    if (histDate) {
      const todayJs = dayjs(new Date())
      let histDayJs = dayjs(histDate)
      if (histDayJs < todayJs) {
        histDayJs = todayJs.subtract(daysback, 'day')
      } else {
        histDayJs = todayJs.add(daysback, 'day')
      }
      setHistDate(histDayJs.toDate())
      setDaysBack(daysback)
    }
  }

  return (
    <div className='form-container'>
      <ScraperHeader name={name} description={description} />
      <form method="post"
        onSubmit={handleSubmit}
        noValidate={false}>
        <UsernamePassword cred={cred} setCred={setCredState} />
        <SaveDirectory config={config} setConfig={setConfigState} />
        <ScrapeJob disabled={false} job={job} setJob={setJobState} placeHolder='["username","username","username"...]' />
        <div className="submit-group">
          <SubmitButton isScraping={isScraping} />
          <CancelBtn configId={config?.ConfigId} isScraping={isScraping} />
          <FormControlLabel control={<Switch checked={isHist} onChange={() => setIsHist(!isHist)} />} label="History Mode" />
          {isHist && <>
            <div>
              <div>
                <label htmlFor='hist-date' className='mt-3'><strong>Date select:</strong></label>
                <DatePicker
                  selected={histDate}
                  onChange={date => calculateDifference(date)}
                  filterDate={isHistable}
                  id='hist-date'
                />
                <div className='d-inline'>Days back: {daysBack}</div>
                <Slider
                  id='daysback'
                  aria-label="Days back"
                  defaultValue={0}
                  shiftStep={1}
                  step={1}
                  marks
                  min={0}
                  max={9}
                  onChange={handleSlideRange}
                  value={daysBack}
                />
              </div>
            </div>
          </>}
        </div>
      </form>
      <ScrapeMetrics config={config} isScraping={isScraping} botCode={botCode} />
    </div>
  )
}

export default React.memo(BbyCpfrScraper)
