import { faTableList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import { Button } from '@mui/material';
import { AlertColor } from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiInput from '@mui/material/Input';
import Slider from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import React, { useState, useEffect } from 'react';

import JobEditModal from '../JobEditModal';
import ScraperHeader from '../ScraperForm/Header';
import SaveDirectory from "../ScraperForm/SaveDirectory"
import UsernamePassword from "../ScraperForm/UsernamePassword"

import Api from "@/api/admin-api.js";
import { getClientShortCoes } from '@/api/data_setup-api';
import ScraperApi from "@/api/scraper-api";
import CancelBtn from '@/components/Buttons/CancelBtn';
import SubmitButton from "@/components/Buttons/ScrapeButton"
import { useSnackbar } from '@/components/Hooks/Notification/SnackBarContext';
import ScrapeMetrics from "@/components/Metrics";
import ScrapeJob from "@/components/ScraperForm/ScrapeJob"
import { SnackbarSeverity, VIQBackCandids, ScraperProps, POLVdsIds } from "@/constants";

import './scrapers.scss'

const Input = styled(MuiInput)`
  width: 42px;
`;

function VIQBackupBot(props: ScraperProps) {
  const {
    botCode,
    name,
    description,
    config,
    setConfigState,
    cred,
    setCredState,
    job,
    setJobState
  } = props;

  // state
  const [isScraping, setIsScraping] = useState<boolean>(false);
  const [isApplyViqLoading, setIsApplyLoading] = useState<boolean>(false);
  // hist state
  const [isHist, setIsHist] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Dayjs>(dayjs(new Date().setMonth(new Date().getMonth() - 3)));
  const [endDate, setEndDate] = useState<Dayjs>(dayjs(new Date()));
  const [delta, setDelta] = useState<number>(26);

  // job edit modal
  const [isEditJobOpen, setIsEditJobOpen] = useState<boolean>(false);
  const [isJobEditLoading, setIsJobEditLoading] = useState<boolean>(false);
  const [polVds, setPolVds] = React.useState<Array<POLVdsIds>>([]);
  const [clientShortCodes, setClientShortCodes] = React.useState<Array<string>>([]);

  // poll isscraping
  useEffect(() => {
    // make initial api call
    Api.getIsScraping(config?.ConfigId)
      .then((res: any) => {
          setIsScraping(res.IsScraping)
      }).fail(() => {
          setIsScraping(false)
      });
    // if a config is selected poll api every 30 seconds unless not scraping
    if (config) {
      const isScrapingInterval = setInterval(() => {
        Api.getIsScraping(config?.ConfigId)
          .then((res: any) => {
              setIsScraping(res.IsScraping)
              if (!res.IsScraping) {
                  clearInterval(isScrapingInterval)
              }
          }).fail(() => {
              clearInterval(isScrapingInterval)
              setIsScraping(false)
          });
      }, 30000);
      return () => {
          clearInterval(isScrapingInterval)
      };
    }
  }, [isScraping, config?.ConfigId]); 

  const showSnack = useSnackbar();

  const handleShowSnackBar = (message: string, severity: AlertColor) => {
    showSnack(message, severity);
  };
  
  function handleSubmit(e: any) {
    // Prevent the browser from reloading the page
    e.preventDefault();
    // Read the form data as json
    const formJson = Object.fromEntries(new FormData(e.target).entries());

    // is hist checked
    if (isHist) {
      formJson.isHist = JSON.stringify(isHist);
      formJson.startDate = startDate.toISOString()
      formJson.endDate = endDate.toISOString()
      formJson.delta = delta.toString()
    }

    if (config?.ConfigId && config.ConfigId > 0) {
      ScraperApi.scrapeViq(config?.ConfigId, formJson)
        .then(() => {
          setIsScraping(true)
          handleShowSnackBar(`Started bot config: "${config?.Name}".`, SnackbarSeverity.INFO);
        }).catch((err: any) => {
          setIsScraping(false)
          let message = ''
          try {
            message = JSON.parse(err.responseText).Message
          } catch {
            message = err.responseText
            if (!message)
              message = 'Error'
          }
          handleShowSnackBar(message, SnackbarSeverity.ERROR);
      });
    } else {
      handleShowSnackBar(`First create a config?.`, SnackbarSeverity.INFO)
    }
  }

  function handleGetVIQBackupCandidates() {
    setIsApplyLoading(true)
    ScraperApi.getViqBackCandids().then((resp: Array<VIQBackCandids>) => {
      // reduce VIQBackCandids and group ClientShortCodes by VDSNumber
      const scrapeObj = resp.reduce((hash: any, obj: VIQBackCandids) => ({...hash, [obj['VDSNumber']]:( hash[obj['VDSNumber']] || [] ).concat(obj.ClientShortCode)}), {})
      setJobState({...job, JobString: JSON.stringify(scrapeObj)})
      setIsApplyLoading(false)
      handleShowSnackBar(`Applied VIQ backup candidates to the bot.`, SnackbarSeverity.INFO)
    }).catch((err: any) => {
      setIsApplyLoading(false)
      handleShowSnackBar(err.statusText, SnackbarSeverity.ERROR)
    })
  }

  const handleChange = (event: Event, newValue: number | number[]) => {
    setDelta(newValue as number);
  };
  const marks = [
    {
      value: 1,
      label: '1 week',
    },
    {
      value: 13,
      label: '3 months',
    },
    {
      value: 26,
      label: '6 months',
    },
    {
      value: 52,
      label: '1 year',
    },
  ];
  const handleBlur = () => {
    if (delta < 1) {
      setDelta(1);
    } else if (delta > 52) {
      setDelta(52);
    }
  };

  function handleChangeStartDate(value: Dayjs | null) {
    const today = new Date();
    if (dayjs(value).isAfter(today))
      setStartDate(dayjs(today))
    else
      setStartDate(dayjs(value))
  }

  function handleChangeEndDate(value: Dayjs | null) {
    const today = new Date();
    if (dayjs(value).isAfter(today))
      setEndDate(dayjs(today))
    else
      setEndDate(dayjs(value))
  }

  function reportsPerVdsId() {
    const weeks = dayjs(new Date()).diff(startDate, 'week');
    if (weeks < delta)
      return 2
    return (Math.round(weeks / delta) * 2).toFixed();
  }

  function monthsAgo() {
    const months = dayjs(new Date()).diff(startDate, 'month', true);
    return months.toFixed(2);
  }

  function timeSpan() {
    const months = dayjs(endDate).diff(startDate, 'month', true);
    return months.toFixed(2);
  }

  function handleEditJob() {
    setIsJobEditLoading(true)
    const done = {api1: false, api2: false};
    ScraperApi.getPolVds(config?.ConfigId, false).then((json: Array<POLVdsIds>) => {
      setPolVds(json);
      done.api1 = true;
      if (done.api1 && done.api2) {
        setIsEditJobOpen(true);
        setIsJobEditLoading(false)
      }
    }).catch((err: any) => {
      handleShowSnackBar(err.responseJSON.Message, SnackbarSeverity.ERROR);
      done.api1 = true;
      if (done.api1 && done.api2) {
        setIsEditJobOpen(true);
        setIsJobEditLoading(false)
      }
    });
    getClientShortCoes().then((json: Array<string>) => {
      setClientShortCodes(json)
      done.api2 = true;
      if (done.api1 && done.api2) {
        setIsEditJobOpen(true);
        setIsJobEditLoading(false)
      }
    }).catch((err: any) => {
      handleShowSnackBar(err.responseJSON.Message, SnackbarSeverity.ERROR);
      done.api2 = true;
      if (done.api1 && done.api2) {
        setIsJobEditLoading(false);
        setIsEditJobOpen(true);
      }
    })
  }
  
  return (
    <div className='form-container'>
      <JobEditModal 
        isOpen={isEditJobOpen} 
        setIsOpen={setIsEditJobOpen} 
        polVds={polVds} 
        clientShortCodes={clientShortCodes} 
        job={ job ? job : {
          ConfigId: 0,
          Name: '',
          Description: '',
          JobString: '{}',
          CreateDate: '',
          ModifiedDate: '',
          ModifiedBy: '',
          ModifiedReason: ''
        }} 
        setJobState={setJobState} />
      <ScraperHeader name={name} description={description} />
      <form method="post" onSubmit={handleSubmit}>
        <UsernamePassword cred={cred} setCred={setCredState} />
        <SaveDirectory config={config} setConfig={setConfigState} />
        <ScrapeJob disabled={false} job={job} setJob={setJobState} placeHolder='{"vdsnum1": ["client shortcode 1", "client shortcode 2"], "vdsnum2": ["client shortcode 1"]}' />
        
        <div className="submit-group">
          <div>
            {/* viq history stuff */}
            {isHist && <>
              <Typography gutterBottom>
                VIQ History Parameters
              </Typography>
              <fieldset className="form-group border py-2 px-5">
                <div className='mt-3'>
                  <div id='two-dates'>
                    <div>
                      <label htmlFor='start-date' className='mb-3'><strong>Start Date</strong> <small>(how far back in history to go)</small></label>
                      <div className="datepicker" id='start-date'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker defaultValue={dayjs(new Date())} onChange={handleChangeStartDate} value={startDate}/>
                        </LocalizationProvider>
                      </div>
                    </div>
                    <div>
                      <label htmlFor='start-date' className='mb-3'><strong>End Date</strong></label>
                      <div className="datepicker" id='end-date'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker defaultValue={dayjs(new Date())} onChange={handleChangeEndDate} value={endDate}/>
                        </LocalizationProvider>
                      </div>
                    </div>
                  </div>
                  <div>
                    <label htmlFor='delta' className='mt-3'><strong>Delta</strong> <small>(number of weeks between reports)</small></label>
                    <div id='delta'>
                      <Slider aria-label="delta" value={delta} onChange={handleChange} valueLabelDisplay="auto" min={1} max={52} marks={marks}/>
                      <Input
                        value={delta}
                        size="small"
                        onChange={(e) => setDelta(parseInt(e.target.value))}
                        onBlur={handleBlur}
                        inputProps={{
                          step: 1,
                          min: 1,
                          max: 52,
                          type: 'number',
                          'aria-labelledby': 'input-slider',
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div>
                <Typography gutterBottom>
                  <strong>History Estimates:</strong>
                </Typography>
                <table>
                  <tbody>
                    <tr>
                      <td><em>Start months ago:</em></td>
                      <td>{monthsAgo()}</td>
                    </tr>
                    <tr>
                      <td><em>Timespan in months:</em></td>
                      <td>{timeSpan()}</td>
                    </tr>
                    <tr>
                      <td><em>Reports per VDS id:</em></td>
                      <td>{reportsPerVdsId()}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </fieldset>
            </>}
            <div className='submit-group'>
              <SubmitButton isScraping={isScraping} />
              <CancelBtn configId={config?.ConfigId} isScraping={isScraping} />
              <Button
                className='btn-wrapper'
                id='applyviqbackupcandidates'
                type="button"
                variant='outlined'
                onClick={handleGetVIQBackupCandidates}
              >
                {isApplyViqLoading ? <CircularProgress size={15}/> : <MergeTypeIcon />} Apply Sat/Sun Job
              </Button>
              <button type="button" className='btn btn-secondary'  onClick={handleEditJob}>
                {isJobEditLoading ? <span id='edit-loading'><CircularProgress size={15}/></span> : <FontAwesomeIcon icon={faTableList} />} Setup VIQ Job
              </button>
              <FormControlLabel control={<Switch checked={isHist} onChange={() => setIsHist(!isHist)} />} label="History Mode" />
            </div>
          </div>
        </div>
      </form>
      <ScrapeMetrics config={config} isScraping={isScraping} botCode={botCode} />
    </div>
  )
}

export default React.memo(VIQBackupBot)
